import { Directive, HostBinding, Input } from '@angular/core';

import { ButtonSize } from './button-size.type';

@Directive({
  selector: '[tkButtonSize]',
  standalone: true,
})
export class ButtonSizeDirective {
  @HostBinding('attr.data-size')
  @Input()
  size: ButtonSize = 'normal';
}
