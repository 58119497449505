import { FocusMonitor } from '@angular/cdk/a11y';
import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { TRACK_DESCENDANTS_FOCUS } from './track-descendants-focus.token';

@Directive({
  selector: '[tkKeyboardFocusMonitor]',
  standalone: true,
})
export class KeyboardFocusMonitorDirective implements OnInit, OnDestroy {
  /**
   * @internal
   */
  @HostBinding('attr.data-keyboard-focused')
  keyboardFocused = false;

  @Input() trackDescendants = true;

  @Output() focusReceived = new EventEmitter<void>();

  private readonly subscription = new Subscription();

  constructor(
    private readonly elementRef: ElementRef,
    private readonly focusMonitor: FocusMonitor,
    private readonly cdr: ChangeDetectorRef,
    private readonly ngZone: NgZone,
    @Inject(TRACK_DESCENDANTS_FOCUS) @Self() @Optional() _trackDescendants: boolean | undefined,
  ) {
    if (typeof _trackDescendants === 'boolean') {
      this.trackDescendants = _trackDescendants;
    }
  }

  ngOnInit(): void {
    this.ngZone.runOutsideAngular(() => {
      this.subscription.add(
        this.focusMonitor.monitor(this.elementRef, this.trackDescendants).subscribe((focusSource) => {
          this.keyboardFocused = focusSource === 'keyboard';
          this.focusReceived.emit();
          this.cdr.markForCheck();
        }),
      );
    });
  }

  ngOnDestroy(): void {
    this.focusMonitor.stopMonitoring(this.elementRef);
    this.subscription.unsubscribe();
  }
}
